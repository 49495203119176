/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import { createSlice } from "@reduxjs/toolkit";
import { map } from "lodash";
import { API } from "../../../utils/axios/index";
import { setErrorMessage } from "../../../utils/helpers/utility_helpers";
import { formatGlobalHierarchyOptions } from "../../../utils/helpers/apiHelpers";
import { setFilteredData } from "../filters/filters";
import { DISCOUNT_LEVELS } from "../../../constants/Constants";

const initialState = {
	loading: 0,
	lengthyOpLoader: false,
	error: null,
	message: "",
	fiscalCalendar: [],
	disableDataLoadScreen: [],
	productConfigurationLevel: {},
	screenConfig: {},
	elementLabels: {},
	globalMetrics: [],
	navConfig: {},
	sseCompletedEvents: [],
	updatedTime: {},
	notifications: [],
	userScreens: {}, // By default access to all screens - change to none later
	userPermissions: null, // No access to actions by default - Change to null
	newSseConnection: false,
	globalConfigOptions: [],
	unreadCount: 0,
};

const globalSlice = createSlice({
	name: "global",
	initialState,
	reducers: {
		requestStart(state, action) {
			state.loading += 1;
			state.error = false;
		},
		requestComplete(state, action) {
			state.loading -= 1;
			state.error = false;
			state.message = action.payload ? action.payload : null;
		},
		requestFail(state, action) {
			const error =
				action.payload || "Something went wrong. Please try again";
			state.loading -= 1;
			state.error = error;
			state.message = "";
		},
		disableLoader(state, action) {
			state.loading = 0;
		},
		requestStartNoLoader(state, action) {
			state.error = false;
		},
		requestCompleteNoLoader(state, action) {
			state.error = false;
			state.message = action.payload ? action.payload : null;
		},
		requestFailNoLoader(state, action) {
			const error =
				action.payload || "Something went wrong. Please try again";
			state.error = error;
			state.message = "";
		},
		toastError(state, action) {
			const error =
				action.payload || "Something went wrong. Please try again";
			state.error = error;
			state.message = "";
		},
		resetToastMessage(state, action) {
			state.error = null;
			state.message = null;
		},
		setScreenDisableDataLoad(state, action) {
			state.disableDataLoadScreen = action.payload;
		},
		setProductConfigurationLevel(state, action) {
			state.productConfigurationLevel = action.payload;
		},
		setFiscalCalendar(state, action) {
			state.fiscalCalendar = action.payload;
		},
		setScreenConfig(state, action) {
			state.screenConfig = action.payload;
		},
		setElementLabels(state, action) {
			state.elementLabels = action.payload;
		},
		setMetrics(state, action) {
			state.globalMetrics = action.payload;
		},
		setNavigationConfig(state, action) {
			state.navConfig = action.payload;
		},
		sseCompleteEvents(state, action) {
			state.sseCompletedEvents = action.payload;
		},
		clearCompletedEvents(state, action) {
			state.sseCompletedEvents = [];
		},
		setUpdatedTime(state, action) {
			state.updatedTime = action.payload;
		},
		setNotifications(state, action) {
			state.notifications = action.payload;
		},
		setUnreadNotificationsCount(state, action) {
			state.unreadCount = action.payload;
		},
		setUserScreens(action, payload) {
			state.userScreens = action.payload;
		},
		setUserPermissions(state, action) {
			state.userPermissions = action.payload;
		},
		toggleLengthyOpLoader(state, action) {
			state.lengthyOpLoader = action.payload;
		},
		setNewSseConnection(state, action) {
			state.newSseConnection = action.payload;
		},
		setGlobalConfigOptions(state, action) {
			state.globalConfigOptions = action.payload;
		},
	},
});

export const {
	requestStart,
	requestComplete,
	requestFail,
	requestStartNoLoader,
	requestCompleteNoLoader,
	requestFailNoLoader,
	toastError,
	resetToastMessage,
	setProductConfigurationLevel,
	setScreenDisableDataLoad,
	setFiscalCalendar,
	setScreenConfig,
	setElementLabels,
	setNavigationConfig,
	sseCompleteEvents,
	clearCompletedEvents,
	setNotifications,
	setUnreadNotificationsCount,
	disableLoader,
	setUserScreens,
	setUserPermissions,
	setUpdatedTime,
	toggleLengthyOpLoader,
	setMetrics,
	setNewSseConnection,
	setGlobalConfigOptions,
} = globalSlice.actions;

export default globalSlice.reducer;

export const getGlobalConfig =
	({ screen }) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		let data = _.cloneDeep(abcd.data);

		let uiConfigAllScreen = data && data.UI_CONFIG_ALL_SCREEN;

		let elementLabels =
			data &&
			data.UI_CONFIG_COMMON_IN_ALL &&
			data.UI_CONFIG_COMMON_IN_ALL.element_labels;

		let navConfig =
			data &&
			data.UI_CONFIG_COMMON_IN_ALL &&
			data.UI_CONFIG_COMMON_IN_ALL.nav_config;

		let metrics_ui =
			data &&
			data.UI_CONFIG_COMMON_IN_ALL &&
			data.UI_CONFIG_COMMON_IN_ALL.metrics_ui;

		let landingScreen =
			screen ||
			(data &&
				data.UI_CONFIG_COMMON_IN_ALL &&
				data.UI_CONFIG_COMMON_IN_ALL.landing_screen) ||
			"DECISION_DASHBOARD";
		let options = uiConfigAllScreen && uiConfigAllScreen[landingScreen];

		let filterList =
			options && options.filter_options ? options.filter_options : [];

		let filtersData = {};
		filterList &&
			map(filterList, (o) => {
				filtersData[o.type] = o.type.includes("dateRange")
					? { startDate: null, endDate: null, weeks: [] }
					: {
							options: [],
							selectedItems: [],
							selectedItemsArray: [],
					  };
			});
		let productConfigurationLevel = (data.UI_CONFIG_COMMON_IN_ALL &&
			data.UI_CONFIG_COMMON_IN_ALL.product_configuration_level) || {
			name: "Item",
			type: "something",
		};
		dispatch(setProductConfigurationLevel(productConfigurationLevel));
		dispatch(setElementLabels(elementLabels));
		dispatch(setMetrics(metrics_ui));
		dispatch(
			setFilteredData({
				payload: filtersData,
				activeScreen: landingScreen,
			})
		);
		dispatch(setScreenConfig(uiConfigAllScreen));
		dispatch(setNavigationConfig(navConfig));
		dispatch(requestComplete());

		// API.get("/config?config_type=ui")
		// 	.then((response) => {
		// 		if (
		// 			response &&
		// 			response.data &&
		// 			response.data.status === true
		// 		) {
		// 			// let data = response.data && response.data.data;
		// 			let data = _.cloneDeep(abcd.data);

		// 			let uiConfigAllScreen = data && data.UI_CONFIG_ALL_SCREEN;

		// 			let elementLabels =
		// 				data &&
		// 				data.UI_CONFIG_COMMON_IN_ALL &&
		// 				data.UI_CONFIG_COMMON_IN_ALL.element_labels;

		// 			let navConfig =
		// 				data &&
		// 				data.UI_CONFIG_COMMON_IN_ALL &&
		// 				data.UI_CONFIG_COMMON_IN_ALL.nav_config;

		// 			let landingScreen =
		// 				screen ||
		// 				(data &&
		// 					data.UI_CONFIG_COMMON_IN_ALL &&
		// 					data.UI_CONFIG_COMMON_IN_ALL.landing_screen) ||
		// 				"DECISION_DASHBOARD";
		// 			let options =
		// 				uiConfigAllScreen && uiConfigAllScreen[landingScreen];

		// 			let filterList =
		// 				options && options.filter_options
		// 					? options.filter_options
		// 					: [];

		// 			let filtersData = {};
		// 			filterList &&
		// 				map(filterList, (o) => {
		// 					filtersData[o.type] = o.type.includes("dateRange")
		// 						? { startDate: null, endDate: null, weeks: [] }
		// 						: {
		// 								options: [],
		// 								selectedItems: [],
		// 								selectedItemsArray: [],
		// 						  };
		// 				});
		// 			let productConfigurationLevel =
		// 				(data.UI_CONFIG_COMMON_IN_ALL &&
		// 					data.UI_CONFIG_COMMON_IN_ALL
		// 						.product_configuration_level) || {
		// 					name: "Item",
		// 					type: "something",
		// 				};
		// 			dispatch(
		// 				setProductConfigurationLevel(productConfigurationLevel)
		// 			);
		// 			dispatch(setElementLabels(elementLabels));
		// 			dispatch(
		// 				setFilteredData({
		// 					payload: filtersData,
		// 					activeScreen: landingScreen,
		// 				})
		// 			);
		// 			dispatch(setScreenConfig(uiConfigAllScreen));
		// 			dispatch(setNavigationConfig(navConfig));
		// 			dispatch(requestComplete());
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		let data = _.cloneDeep(abcd.data);

		// 		let uiConfigAllScreen = data && data.UI_CONFIG_ALL_SCREEN;

		// 		let elementLabels =
		// 			data &&
		// 			data.UI_CONFIG_COMMON_IN_ALL &&
		// 			data.UI_CONFIG_COMMON_IN_ALL.element_labels;

		// 		let navConfig =
		// 			data &&
		// 			data.UI_CONFIG_COMMON_IN_ALL &&
		// 			data.UI_CONFIG_COMMON_IN_ALL.nav_config;

		// 		let landingScreen =
		// 			screen ||
		// 			(data &&
		// 				data.UI_CONFIG_COMMON_IN_ALL &&
		// 				data.UI_CONFIG_COMMON_IN_ALL.landing_screen) ||
		// 			"DECISION_DASHBOARD";
		// 		let options =
		// 			uiConfigAllScreen && uiConfigAllScreen[landingScreen];

		// 		let filterList =
		// 			options && options.filter_options
		// 				? options.filter_options
		// 				: [];

		// 		let filtersData = {};
		// 		filterList &&
		// 			map(filterList, (o) => {
		// 				filtersData[o.type] = o.type.includes("dateRange")
		// 					? { startDate: null, endDate: null, weeks: [] }
		// 					: {
		// 							options: [],
		// 							selectedItems: [],
		// 							selectedItemsArray: [],
		// 					  };
		// 			});
		// 		let productConfigurationLevel = (data.UI_CONFIG_COMMON_IN_ALL &&
		// 			data.UI_CONFIG_COMMON_IN_ALL
		// 				.product_configuration_level) || {
		// 			name: "Item",
		// 			type: "something",
		// 		};
		// 		dispatch(
		// 			setProductConfigurationLevel(productConfigurationLevel)
		// 		);
		// 		dispatch(setElementLabels(elementLabels));
		// 		dispatch(
		// 			setFilteredData({
		// 				payload: filtersData,
		// 				activeScreen: landingScreen,
		// 			})
		// 		);
		// 		dispatch(setScreenConfig(uiConfigAllScreen));
		// 		dispatch(setNavigationConfig(navConfig));
		// 		dispatch(requestComplete());

		// 		// console.log(error);
		// 		// const errorMessage = setErrorMessage(error);
		// 		// dispatch(requestFail(errorMessage));
		// 	});
	};

export const removeLoader = () => (dispatch, getState) => {
	const loading = getState().global.loading;
	if (loading !== 0) {
		dispatch(disableLoader());
	}
};

export const getFiscalCalendar = (payload) => (dispatch, getState) => {
	dispatch(requestStart());
	return API.post("/fiscal-calendar", payload)
		.then((response) => {
			if (response.data && response.data.status === 200) {
				const { data } = response.data;
				// const fiscalCalendarData = formatCalendarData(data);
				dispatch(setFiscalCalendar(data));
				dispatch(requestComplete());
				return data;
			} else {
				dispatch(requestFail());
				return false;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
			return false;
		});
};

export const getNotifications =
	(showLoader = true) =>
	(dispatch, getState) => {
		if (showLoader) {
			dispatch(requestStart());
		} else {
			dispatch(requestStartNoLoader());
		}
		return API.post("/notifications")
			.then((response) => {
				if (response.data && response.data.status === 200) {
					const { data } = response.data;
					// Check which notifications are new and add read = false
					dispatch(setNotifications(data));
					if (showLoader) {
						dispatch(requestComplete());
					} else {
						dispatch(requestCompleteNoLoader());
					}
					return data;
				}
			})
			.catch((error) => {
				console.log(error);
				const errorMessage = setErrorMessage(error);
				if (showLoader) {
					dispatch(requestFail(errorMessage));
				} else {
					dispatch(requestFailNoLoader(errorMessage));
				}
				return false;
			});
	};

export const updateNotificationStatus = (payload) => (dispatch, getState) => {
	dispatch(requestStartNoLoader());
	API.put("/notifications", payload)
		.then((response) => {
			if (response.data && response.data.status === 200) {
				dispatch(requestCompleteNoLoader());
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
			dispatch(requestComplete());
		});
};

export const addNewNotification =
	(notificationsList) => (dispatch, getState) => {
		const notifications = _.cloneDeep(getState().global.notifications);
		const updatedNotifications = [...notificationsList, ...notifications];
		dispatch(setNotifications(updatedNotifications));
	};

export const updateUnreadCount = (count) => (dispatch, getState) => {
	const tempCount = count;
	// const updatedNotifications = [...notificationsList, ...notifications];
	dispatch(setUnreadNotificationsCount(tempCount));
};

export const getUserScreens = () => (dispatch, getState) => {
	dispatch(requestStart());
	API.get("/user-management/screens/")
		.then((response) => {
			if (response.data && response.data.status === true) {
				const { data } = response.data;
				dispatch(setUserScreens(data));
				dispatch(requestComplete());
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const getUserHierarchy = (screen) => (dispatch, getState) => {
	dispatch(requestStart());
	API.get("/user-management/screen-hierarchy?app=pricesmart&screen=" + screen)
		.then((response) => {
			if (response.data && response.data.status === true) {
				const { data } = response.data;
				if (data) {
					dispatch(setUserPermissions(data));
				}
				dispatch(requestComplete());
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
		});
};

export const getGlobalConfigOptions = (payload) => (dispatch, getState) => {
	dispatch(requestStart());
	return API.post("/model-data", payload)
		.then((response) => {
			if (response.data && response.data.status === 200) {
				const { data } = response.data;
				// const fiscalCalendarData = formatCalendarData(data);
				dispatch(
					setGlobalConfigOptions(
						formatGlobalHierarchyOptions(data?.[0])
					)
				);
				dispatch(requestComplete());
			} else {
				dispatch(requestFail());
				return false;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
			return false;
		});
};

export const validateOperation = (payload) => (dispatch) => {
	dispatch(requestStart());
	return API.post("/validate_edit", payload)
		.then((response) => {
			if (response.data) {
				const { data } = response.data;
				// const fiscalCalendarData = formatCalendarData(data);

				dispatch(requestComplete());

				return data;
			} else {
				dispatch(requestFail());
				return false;
			}
		})
		.catch((error) => {
			console.log(error);
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
			return false;
		});
};

let abcd = {
	data: {
		UI_CONFIG_ALL_SCREEN: {
			DECISION_DASHBOARD: {
				ui_elements: {
					metrics: [
						{
							key: "gross_margin",
							display: "GM$",
						},
						{
							key: "sales_units",
							display: "Sales Units",
						},
						{
							key: "sales_dollars",
							display: "Sales $",
						},
						// {
						// 	key: "margin_rate",
						// 	display: "Margin Rate",
						// },
					],
					table_setup: [
						{
							key: "total",
							display: "Total",
						},
						{
							key: "reg",
							display: "Reg Price",
							default: true,
						},
						{
							key: "clearance",
							display: "Clearance",
						},
					],
				},
				filter_options: [
					{
						type: "product_h1",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					{
						type: "product_h2",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					// {
					// 	type: "product_h3",
					// 	is_mandatory: false,
					// 	is_multiple_selection: true,
					// 	filter_endpoint: "filters",
					// 	filter_type: "product_hierarchy",
					// },
					// {
					// 	type: "product_h4",
					// 	is_mandatory: false,
					// 	is_multiple_selection: true,
					// 	filter_endpoint: "filters",
					// 	filter_type: "product_hierarchy",
					// },
					// {
					// 	type: "brand",
					// 	is_mandatory: false,
					// 	is_multiple_selection: true,
					// 	filter_endpoint: "filters",
					// 	filter_type: "product_hierarchy",
					// },
					{
						type: "dateRange",
						is_mandatory: false,
						filter_endpoint: "",
						filter_type: "dateRange",
						use_default: true,
						filter_group: 2,
						filter_group_label: "Promo & Events Setup",
						default_type: "decision_dashboard",
					},
					{
						type: "channelOptions",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "",
						filter_type: "channelOptions",
						filter_group: 2,
					},
					{
						type: "adTypeOptions",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "",
						filter_type: "adTypeOptions",
						filter_group: 2,
					},
					{
						type: "events",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "get-events",
						filter_type: "events",
						filter_group: 2,
						filter_model: "METRICS_EVENTS_PROMO_LIST",
					},
					{
						type: "promos",
						is_mandatory: false,
						is_multiple_selection: true,
						filter_endpoint: "",
						filter_type: "promos",
						filter_group: 2,
					},
				],
				table_config: {
					decision_dashboard_promo_week_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Offers",
								key: "promo_name_id",
								action: "inlineEditCell",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: true,
								hideHeaderCheckbox: false,
								row_group: true,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Start Date",
								key: "start_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "date",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "End Date",
								key: "end_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "date",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Created By",
								key: "promo_created_by",
								action: null, //"offer_comment",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "text",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Comments",
								key: "offer_comment",
								action: null, // "offer_comment",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "text",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Performance",
								key: "performance",
								action: "performance",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "performance",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "IA Recommended Discount", //projected
								key: "ia_recommended",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: false,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Finalized Discount",
								key: "discount",
								action: "show_type_and_value",
								hidden: false,
								aggregate: null,
								sorting: false,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Products",
								key: "product_count",
								action: "textButton",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "number",
							},
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "Stores",
							// 	key: "store_count",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	checkbox_selection: false,
							// 	row_group: false,
							//  comparator: "number"
							// },
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "Avg. Basket Size",
							// 	key: "avg_basket_size",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	checkbox_selection: false,
							// 	row_group: false,
							//  comparator: "number"
							// },
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "Units Per Txn",
							// 	key: "unit_per_txn",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	checkbox_selection: false,
							// 	row_group: false,
							//  comparator: "number"
							// },
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "# of Txn",
							// 	key: "no_of_txn",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	checkbox_selection: false,
							// 	row_group: false,
							//  comparator: "number"
							// },
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Markdown $",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized",
										key: "markdown_actual",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Finalized",
										key: "markdown_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Sales Units",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized units",
										key: "sales_units_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "sales_units_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "sales_units_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental contribution",
										key: "sales_units_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "sales_units_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "sales_units_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Revenue",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized Revenue",
										key: "sales_dollars_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "sales_dollars_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										comparator: "number",
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "sales_dollars_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "sales_dollars_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "sales_dollars_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "sales_dollars_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Margin",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized Margin",
										key: "gross_margin_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "gross_margin_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "gross_margin_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "gross_margin_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "gross_margin_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "gross_margin_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Margin Rate",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized",
										key: "margin_rate_actuals",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Finalized",
										key: "margin_rate_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
								],
							},
						],
						add_metrics_new: [
							{
								label: "Margin",
								value: "gm_dollars",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"gross_margin_baseline",
									"gross_margin_target",
									"gross_margin_ia_recommended",
									"gross_margin_ia_incremental",
									"gross_margin_ia_finalized",
									"gross_margin_actualized",
								],
							},
							{
								label: "Sales Units",
								value: "sales_units",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"sales_units_baseline",
									"sales_units_target",
									"sales_units_ia_recommended",
									"sales_units_ia_incremental",
									"sales_units_ia_finalized",
									"sales_units_actualized",
								],
							},
							{
								label: "Revenue",
								value: "sales_dollars",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"sales_dollars_baseline",
									"sales_dollars_target",
									"sales_dollars_ia_recommended",
									"sales_dollars_ia_incremental",
									"sales_dollars_ia_finalized",
									"sales_dollars_actualized",
								],
							},
							{
								label: "Margin Rate",
								value: "margin_rate",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"margin_rate_actuals",
									"margin_rate_finalized",
								],
							},
						],
						add_metrics: {},
					},
					decision_dashboard_promo_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Offers",
								key: "promo_name",
								action: "inlineEditCellNonGroup",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: true,
								hideHeaderCheckbox: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Is Hero",
								key: "is_hero_promo",
								action: null,
								hidden: true,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								filter_options: true,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Start Date",
								key: "start_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "date",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "End Date",
								key: "end_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "date",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Created By",
								key: "promo_created_by",
								action: null, //"offer_comment",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "text",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Comments",
								key: "offer_comment",
								action: "offer_comment",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "text",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Performance",
								key: "performance",
								action: "performance",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "performance",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "IA Recommended Discount", //projected
								key: "ia_recommended",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: false,
								filter: false,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Finalized Discount",
								key: "discount",
								action: "show_type_and_value",
								hidden: false,
								aggregate: null,
								sorting: false,
								filter: false,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Products",
								key: "product_count",
								action: "textButton",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "number",
							},
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "Stores",
							// 	key: "store_count",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	checkbox_selection: false,
							// 	row_group: false,
							//  comparator: "number"
							// },
							{
								type: "static_column",
								mapping: "data",
								header: "Avg. Basket Size",
								key: "avg_basket_size",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "number",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Units Per Txn",
								key: "unit_per_txn",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "number",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "# of Txn",
								key: "no_of_txn",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "number",
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Markdown $",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized",
										key: "markdown_actual",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: false,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Finalized",
										key: "markdown_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Sales Units",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized units",
										key: "sales_units_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "sales_units_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "sales_units_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "sales_units_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "sales_units_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "sales_units_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Revenue",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized Revenue",
										key: "sales_dollars_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "sales_dollars_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "sales_dollars_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "sales_dollars_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "sales_dollars_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "sales_dollars_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Margin",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized Margin",
										key: "gross_margin_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "gross_margin_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "gross_margin_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "gross_margin_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "gross_margin_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "gross_margin_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Margin Rate",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized",
										key: "margin_rate_actuals",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Finalized",
										key: "margin_rate_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
								],
							},
						],
						add_metrics_new: [
							{
								label: "Margin",
								value: "gm_dollars",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"gross_margin_baseline",
									"gross_margin_target",
									"gross_margin_ia_recommended",
									"gross_margin_ia_incremental",
									"gross_margin_ia_finalized",
									"gross_margin_actualized",
								],
							},
							{
								label: "Sales Units",
								value: "sales_units",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"sales_units_baseline",
									"sales_units_target",
									"sales_units_ia_recommended",
									"sales_units_ia_incremental",
									"sales_units_ia_finalized",
									"sales_units_actualized",
								],
							},
							{
								label: "Revenue",
								value: "sales_dollars",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"sales_dollars_baseline",
									"sales_dollars_target",
									"sales_dollars_ia_recommended",
									"sales_dollars_ia_incremental",
									"sales_dollars_ia_finalized",
									"sales_dollars_actualized",
								],
							},
							{
								label: "Margin Rate",
								value: "margin_rate",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"margin_rate_actuals",
									"margin_rate_finalized",
								],
							},
						],
						add_metrics: {},
					},
					decision_dashboard_events_promo_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Event Name",
								key: "event_name",
								action: "eventName", //"event_name",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: true,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Offers",
								key: "promo_name",
								action: "promo_name_display",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								hideHeaderCheckbox: false,
								row_group: false,
								comparator: "text",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Is Hero",
								key: "is_hero_promo",
								action: null,
								hidden: true,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								filter_options: true,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Start Date",
								key: "start_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "date",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "End Date",
								key: "end_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "date",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Created By",
								key: "promo_created_by",
								action: null, //"offer_comment",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "text",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Comments",
								key: "offer_comment",
								action: "null",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "text",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Performance",
								key: "performance",
								action: "performance",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "performance",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "IA Recommended Discount", //projected
								key: "ia_recommended",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Finalized Discount",
								key: "discount",
								action: "show_type_and_value",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Products",
								key: "product_count",
								action: "textButton",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "number",
							},
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "Stores",
							// 	key: "store_count",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	checkbox_selection: false,
							// 	row_group: false,
							//  comparator: "number"
							// },
							{
								type: "static_column",
								mapping: "data",
								header: "Avg. Basket Size",
								key: "avg_basket_size",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "number",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Units Per Txn",
								key: "unit_per_txn",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "number",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "# of Txn",
								key: "no_of_txn",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "number",
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Markdown $",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized",
										key: "markdown_actual",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: false,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Finalized",
										key: "markdown_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Sales Units",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized units",
										key: "sales_units_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "sales_units_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "sales_units_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "sales_units_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "sales_units_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "sales_units_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Revenue",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized Revenue",
										key: "sales_dollars_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "sales_dollars_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "sales_dollars_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "sales_dollars_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "sales_dollars_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "sales_dollars_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Margin",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized Margin",
										key: "gross_margin_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "gross_margin_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "gross_margin_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "gross_margin_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "gross_margin_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "gross_margin_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Margin Rate",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized",
										key: "margin_rate_actuals",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Finalized",
										key: "margin_rate_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
								],
							},
						],
						add_metrics_new: [
							{
								label: "Margin",
								value: "gm_dollars",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"gross_margin_baseline",
									"gross_margin_target",
									"gross_margin_ia_recommended",
									"gross_margin_ia_incremental",
									"gross_margin_ia_finalized",
									"gross_margin_actualized",
								],
							},
							{
								label: "Sales Units",
								value: "sales_units",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"sales_units_baseline",
									"sales_units_target",
									"sales_units_ia_recommended",
									"sales_units_ia_incremental",
									"sales_units_ia_finalized",
									"sales_units_actualized",
								],
							},
							{
								label: "Revenue",
								value: "sales_dollars",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"sales_dollars_baseline",
									"sales_dollars_target",
									"sales_dollars_ia_recommended",
									"sales_dollars_ia_incremental",
									"sales_dollars_ia_finalized",
									"sales_dollars_actualized",
								],
							},
							{
								label: "Margin Rate",
								value: "margin_rate",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"margin_rate_actuals",
									"margin_rate_finalized",
								],
							},
						],
						add_metrics: {},
					},
					decision_dashboard_events_week_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Event Name",
								key: "event_name",
								action: "eventName", //"event_name",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: true,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Start Date",
								key: "start_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "date",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "End Date",
								key: "end_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "date",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Created By",
								key: "event_created_by",
								action: null, //"offer_comment",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "text",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Comments",
								key: "offer_comment",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "text",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Performance",
								key: "performance",
								action: "performance",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "performance",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "IA Recommended Discount", //projected
								key: "ia_recommended",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Finalized Discount",
								key: "discount",
								action: "show_type_and_value",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Products",
								key: "product_count",
								action: "textButton",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "number",
							},
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "Stores",
							// 	key: "store_count",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	checkbox_selection: false,
							// 	row_group: false,
							//  comparator: "number"
							// },
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "Avg. Basket Size",
							// 	key: "avg_basket_size",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	checkbox_selection: false,
							// 	row_group: false,
							//  comparator: "number"
							// },
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "Units Per Txn",
							// 	key: "unit_per_txn",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	checkbox_selection: false,
							// 	row_group: false,
							//  comparator: "number"
							// },
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "# of Txn",
							// 	key: "no_of_txn",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	checkbox_selection: false,
							// 	row_group: false,
							//  comparator: "number"
							// },
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Markdown $",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized",
										key: "markdown_actual",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: false,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: " Finalized",
										key: "markdown_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Sales Units",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized units",
										key: "sales_units_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "sales_units_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "sales_units_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "sales_units_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "sales_units_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "sales_units_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Revenue",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized Revenue",
										key: "sales_dollars_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "sales_dollars_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "sales_dollars_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "sales_dollars_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "sales_dollars_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "sales_dollars_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Margin",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized Margin",
										key: "gross_margin_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "gross_margin_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "gross_margin_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "gross_margin_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "gross_margin_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "gross_margin_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Margin Rate",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized",
										key: "margin_rate_actuals",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Finalized",
										key: "margin_rate_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
								],
							},
						],
						add_metrics_new: [
							{
								label: "Margin",
								value: "gm_dollars",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"gross_margin_baseline",
									"gross_margin_target",
									"gross_margin_ia_recommended",
									"gross_margin_ia_incremental",
									"gross_margin_ia_finalized",
									"gross_margin_actualized",
								],
							},
							{
								label: "Sales Units",
								value: "sales_units",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"sales_units_baseline",
									"sales_units_target",
									"sales_units_ia_recommended",
									"sales_units_ia_incremental",
									"sales_units_ia_finalized",
									"sales_units_actualized",
								],
							},
							{
								label: "Revenue",
								value: "sales_dollars",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"sales_dollars_baseline",
									"sales_dollars_target",
									"sales_dollars_ia_recommended",
									"sales_dollars_ia_incremental",
									"sales_dollars_ia_finalized",
									"sales_dollars_actualized",
								],
							},
							{
								label: "Margin Rate",
								value: "margin_rate",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"margin_rate_actuals",
									"margin_rate_finalized",
								],
							},
						],
						add_metrics: {},
					},
					decision_dashboard_events_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Event Name",
								key: "event_name",
								action: "eventName", //"event_name",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "text",
								// row_span: true
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Start Date",
								key: "start_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "date",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "End Date",
								key: "end_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "date",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Created By",
								key: "event_created_by",
								action: null, //"offer_comment",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "text",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Performance",
								key: "performance",
								action: "performance",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "performance",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "IA Recommended Discount", //projected
								key: "ia_recommended",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Finalized Discount",
								key: "discount",
								action: "show_type_and_value",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Products",
								key: "product_count",
								action: "textButton",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "number",
							},
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "Stores",
							// 	key: "store_count",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	checkbox_selection: false,
							// 	row_group: false,
							//  comparator: "number"
							// },
							{
								type: "static_column",
								mapping: "data",
								header: "Avg. Basket Size",
								key: "avg_basket_size_event",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "number",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Units Per Txn",
								key: "unit_per_txn_event",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "number",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "# of Txn",
								key: "no_of_txn_event",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "number",
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Markdown $",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized",
										key: "markdown_actual",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: false,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: " Finalized",
										key: "markdown_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Sales Units",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized units",
										key: "sales_units_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "sales_units_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "sales_units_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "sales_units_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "sales_units_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "sales_units_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Revenue",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized Revenue",
										key: "sales_dollars_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "sales_dollars_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "sales_dollars_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "sales_dollars_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "sales_dollars_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "sales_dollars_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Margin",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized Margin",
										key: "gross_margin_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "gross_margin_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "gross_margin_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "gross_margin_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "gross_margin_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "gross_margin_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number"
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Margin Rate",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized",
										key: "margin_rate_actuals",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Finalized",
										key: "margin_rate_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
								],
							},
						],
						add_metrics_new: [
							{
								label: "Margin",
								value: "gm_dollars",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"gross_margin_baseline",
									"gross_margin_target",
									"gross_margin_ia_recommended",
									"gross_margin_ia_incremental",
									"gross_margin_ia_finalized",
									"gross_margin_actualized",
								],
							},
							{
								label: "Sales Units",
								value: "sales_units",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"sales_units_baseline",
									"sales_units_target",
									"sales_units_ia_recommended",
									"sales_units_ia_incremental",
									"sales_units_ia_finalized",
									"sales_units_actualized",
								],
							},
							{
								label: "Revenue",
								value: "sales_dollars",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"sales_dollars_baseline",
									"sales_dollars_target",
									"sales_dollars_ia_recommended",
									"sales_dollars_ia_incremental",
									"sales_dollars_ia_finalized",
									"sales_dollars_actualized",
								],
							},
							{
								label: "Margin Rate",
								value: "margin_rate",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"margin_rate_actuals",
									"margin_rate_finalized",
								],
							},
						],
						add_metrics: {},
					},
					decision_dashboard_events_promo_week_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Event Name",
								key: "event_name",
								action: "eventName", //"event_name",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: true,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Offers",
								key: "promo_name",
								action: "promo_name_display",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								hideHeaderCheckbox: false,
								row_group: true,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Is Hero",
								key: "is_hero_promo",
								action: null,
								hidden: true,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								filter_options: true,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Start Date",
								key: "start_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "date",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "End Date",
								key: "end_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "date",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Created By",
								key: "promo_created_by",
								action: null, //"offer_comment",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "text",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Comments",
								key: "offer_comment",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "text",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Performance",
								key: "performance",
								action: "performance",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "performance",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "IA Recommended Discount", //projected
								key: "ia_recommended",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Finalized Discount",
								key: "discount",
								action: "show_type_and_value",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Products",
								key: "product_count",
								action: "textButton",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "number",
							},
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "Stores",
							// 	key: "store_count",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	checkbox_selection: false,
							// 	row_group: false,
							//  comparator: "number",
							// },
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "Avg. Basket Size",
							// 	key: "avg_basket_size",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	checkbox_selection: false,
							// 	row_group: false,
							//  comparator: "number",
							// },
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "Units Per Txn",
							// 	key: "unit_per_txn",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	checkbox_selection: false,
							// 	row_group: false,
							//  comparator: "number",
							// },
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "# of Txn",
							// 	key: "no_of_txn",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	checkbox_selection: false,
							// 	row_group: false,
							//  comparator: "number",
							// },
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Markdown $",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized",
										key: "markdown_actual",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: false,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Finalized",
										key: "markdown_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Sales Units",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized units",
										key: "sales_units_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "sales_units_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "sales_units_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "sales_units_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "sales_units_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number",
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "sales_units_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number",
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Revenue",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized Revenue",
										key: "sales_dollars_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "sales_dollars_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "sales_dollars_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "sales_dollars_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "sales_dollars_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number",
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "sales_dollars_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number",
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Margin",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized Margin",
										key: "gross_margin_actualized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "gross_margin_ia_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline Contribution",
										key: "gross_margin_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental Contribution",
										key: "gross_margin_ia_incremental",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "gross_margin_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number",
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected (Recommended)",
									// 	key: "gross_margin_ia_recommended",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									//  comparator: "number",
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Margin Rate",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Actualized",
										key: "margin_rate_actuals",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Finalized",
										key: "margin_rate_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										comparator: "number",
									},
								],
							},
						],
						add_metrics_new: [
							{
								label: "Margin",
								value: "gm_dollars",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"gross_margin_baseline",
									"gross_margin_target",
									"gross_margin_ia_recommended",
									"gross_margin_ia_incremental",
									"gross_margin_ia_finalized",
									"gross_margin_actualized",
								],
							},
							{
								label: "Sales Units",
								value: "sales_units",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"sales_units_baseline",
									"sales_units_target",
									"sales_units_ia_recommended",
									"sales_units_ia_incremental",
									"sales_units_ia_finalized",
									"sales_units_actualized",
								],
							},
							{
								label: "Revenue",
								value: "sales_dollars",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"sales_dollars_baseline",
									"sales_dollars_target",
									"sales_dollars_ia_recommended",
									"sales_dollars_ia_incremental",
									"sales_dollars_ia_finalized",
									"sales_dollars_actualized",
								],
							},
							{
								label: "Margin Rate",
								value: "margin_rate",
								metric_name: "Metrics",
								metric_type: "drop_down",
								columns_included: [
									"margin_rate_actuals",
									"margin_rate_finalized",
								],
							},
						],
						add_metrics: {},
					},
					decision_dashboard_product_details_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "SKU ID",
								key: "item_id",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								hideHeaderCheckbox: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Product Name",
								key: "product_name",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								hideHeaderCheckbox: false,
								// row_group: true,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Base Price",
								key: "base_price",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Inventory OH",
								key: "inventory",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Class",
								key: "class",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Sub Class",
								key: "sub_class",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
						],
						add_metrics: {},
					},
				},
			},
			CREATE_PROMO_SELECT_PRODUCTS: {
				filter_options: [
					{
						type: "product_h1",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					{
						type: "product_h2",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					{
						type: "product_h3",
						is_mandatory: false,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					{
						type: "product_h4",
						is_mandatory: false,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					{
						type: "brand",
						is_mandatory: false,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
				],
				table_config: {
					product_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Hero SKU",
								key: "is_hero",
								action: "hero_sku",
								hidden: false,
								aggregate: null,
								sorting: false,
								filter: true,
								pinned: false,
								checkbox_selection: true,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "SKU Id",
								key: "product_h5_id",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Active",
								key: "is_active",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Web Enabled",
								key: "web_designation_cd",
								action: "convert_boolean",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "In store",
								key: "store_pickup_eligible_ind",
								action: "convert_boolean",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Inventory OH",
								key: "inventory",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "DAM",
								key: "image_exists",
								action: "convert_boolean",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Description",
								key: "product_h5_name",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Base Price",
								key: "price",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Comp Value",
								key: "comp_value",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
						],
						add_metrics: {},
					},
					invalid_sku_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "SKU Id",
								key: "product_h5_id",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Status",
								key: "product_h5_name",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
						],
						add_metrics: {},
					},
				},
			},
			CREATE_PROMO_SELECT_STORES: {
				filter_options: [
					{
						type: "store_h1",
						is_mandatory: true,
						is_multiple_selection: false,
						filter_endpoint: "filters",
						filter_type: "store_hierarchy",
						filter_group: 1,
					},
					{
						type: "store_h2",
						is_mandatory: true,
						is_multiple_selection: false,
						filter_endpoint: "filters",
						filter_type: "store_hierarchy",
						filter_group: 1,
					},
					{
						type: "store_h3",
						is_mandatory: true,
						is_multiple_selection: false,
						filter_endpoint: "filters",
						filter_type: "store_hierarchy",
						filter_group: 1,
					},
					{
						type: "store_h5",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "store_hierarchy",
						filter_group: 1,
					},
				],
			},
			PROMO_SIMULATOR: {
				table_config: {
					scenario_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Id",
								key: "id",
								action: null,
								hidden: true,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Name",
								key: "name",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "SKU Id",
								key: "product_h5_id_sim",
								action: null,
								hidden: true,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Price",
								key: "price",
								action: null,
								hidden: true,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Comp Value",
								key: "comp_value_sim",
								action: null,
								hidden: true,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "IA Recommended",
								key: "ia_recommended",
								action: "finalize_edit",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: false,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Scenario 1",
								key: "scenario_1",
								action: "finalize_edit",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: false,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Scenario 2",
								key: "scenario_2",
								action: "finalize_edit",
								hidden: true,
								aggregate: null,
								sorting: true,
								filter: false,
								pinned: false,
								row_group: false,
							},
						],
						add_metrics: {},
					},
					scenario_details_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "SKU Id",
								key: "id",
								action: null,
								hidden: true,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Name",
								key: "hierarchy_name",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Margin",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "IA Recommended",
										key: "margin_ia_recommended",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Scenario 1",
										key: "margin_scenario_1",
										action: null,
										hidden: true,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Scenario 2",
										key: "margin_scenario_2",
										action: null,
										hidden: true,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
								],
							},

							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Revenue",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "IA Recommended",
										key: "sales_ia_recommended",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Scenario 1",
										key: "sales_scenario_1",
										action: null,
										hidden: true,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Scenario 2",
										key: "sales_scenario_2",
										action: null,
										hidden: true,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
								],
							},

							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Sales Units",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "IA Recommended",
										key: "sales_units_ia_recommended",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Scenario 1",
										key: "sales_units_scenario_1",
										action: null,
										hidden: true,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Scenario 2",
										key: "sales_units_scenario_2",
										action: null,
										hidden: true,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
								],
							},

							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Incremental Margin",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "IA Recommended",
										key: "incremental_margin_ia_recommended",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Scenario 1",
										key: "incremental_margin_scenario_1",
										action: null,
										hidden: true,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Scenario 2",
										key: "incremental_margin_scenario_2",
										action: null,
										hidden: true,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
								],
							},

							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Incremental Revenue",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "IA Recommended",
										key: "incremental_sales_ia_recommended",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Scenario 1",
										key: "incremental_sales_scenario_1",
										action: null,
										hidden: true,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Scenario 2",
										key: "incremental_sales_scenario_2",
										action: null,
										hidden: true,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
								],
							},

							{
								type: "static_column",
								mapping: "data",
								hidden: true,
								header: "Incremental Sales Units",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "IA Recommended",
										key: "incremental_sales_units_ia_recommended",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Scenario 1",
										key: "incremental_sales_units_scenario_1",
										action: null,
										hidden: true,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Scenario 2",
										key: "incremental_sales_units_scenario_2",
										action: null,
										hidden: true,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
								],
							},
						],
						add_metrics: {},
					},
				},
			},
			PROMO_WORKBENCH: {
				filter_options: [
					{
						type: "product_h1",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					{
						type: "product_h2",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					{
						type: "product_h3",
						is_mandatory: false,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					{
						type: "product_h4",
						is_mandatory: false,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					{
						type: "dateRange",
						is_mandatory: true,
						filter_endpoint: "",
						filter_type: "dateRange",
						use_default: true,
						filter_group: 1,
					},
					{
						type: "channelOptions",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "",
						filter_type: "channelOptions",
						filter_group: 1,
					},
					{
						type: "adTypeOptions",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "",
						filter_type: "adTypeOptions",
						filter_group: 1,
					},
					{
						type: "events",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "get-events",
						filter_type: "events",
						filter_group: 1,
						filter_model: "METRICS_EVENTS_FILTERS_LIST",
					},
				],
				table_config: {
					promo_workbench_all_promo_table: {
						table_header: [
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "Id",
							// 	key: "counter",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	checkbox_selection: true,
							// 	row_group: false,
							// },
							{
								type: "static_column",
								mapping: "data",
								header: "Offer Name",
								key: "promo_name",
								action: "promo_name",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: true,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Is Hero",
								key: "is_hero_promo",
								action: null,
								hidden: true,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								filter_options: true,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Event",
								key: "event_name",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Start Date",
								key: "start_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "End Date",
								key: "end_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Created By",
								key: "created_by",
								action: null, //"offer_comment",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "text",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Offer Status",
								key: "lifecycle",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Comments",
								key: "offer_comment",
								action: "offer_comment",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Products",
								key: "products",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "Stores",
							// 	key: "stores",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	row_group: false,
							// },
							{
								type: "static_column",
								mapping: "data",
								header: "Performance",
								key: "performance",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Markdown $",
								key: "markdown_budget",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Incremental Units (Finalized)",
								key: "units_incremental_finalized",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Incremental Units (IA Reco)",
								key: "units_incremental_ia",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Units Target",
								key: "units_target",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Incremental Revenue (Finalized)",
								key: "revenue_incremental_finalized",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Incremental Revenue (IA Reco)",
								key: "revenue_incremental_ia",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Revenue Targets",
								key: "revenue_target",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Incremental Margin (Finalized)",
								key: "gross_margin_incremental_finalized",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Incremental Margin (IA Reco)",
								key: "gross_margin_incremental_ia",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Margin Target",
								key: "gross_margin_target",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Maximization Metric",
								key: "maximization_metric",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Discount Type",
								key: "discount_type",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "Discount Range",
							// 	key: "discount_range",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	row_group: false,
							// },
							{
								type: "static_column",
								mapping: "data",
								header: "Discount Level - Products",
								key: "discount_level",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
						],
						add_metrics: {},
					},
					finalize_error_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "SKU",
								key: "conflicting_product_h5_id",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "In Promo",
								key: "promo_name",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Conflicting With",
								key: "conflicting_promo_name",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Conflict Type",
								key: "conflict_type",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
						],
					},
				},
			},
			REPORTING: {
				filter_options: [
					{
						type: "product_h1",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
						filter_group_label: "Product Hierarchy",
					},
					{
						type: "product_h2",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					{
						type: "product_h3",
						is_mandatory: false,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					{
						type: "product_h4",
						is_mandatory: false,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					{
						type: "dateRange",
						is_mandatory: true,
						filter_endpoint: "",
						filter_type: "dateRange",
						use_default: true,
						default_type: "past",
						filter_group: 2,
						filter_group_label: "Promo & Events Setup",
						disable_date_type: "disableFuture",
					},
					{
						type: "channelOptions",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "",
						filter_type: "channelOptions",
						filter_group: 2,
					},
					{
						type: "adTypeOptions",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "",
						filter_type: "adTypeOptions",
						filter_group: 2,
					},
					{
						type: "events",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "get-events",
						filter_type: "events",
						filter_group: 2,
						filter_model: "METRICS_REPORTING_EVENT_FILTER",
					},
					{
						type: "promos",
						is_mandatory: false,
						is_multiple_selection: true,
						filter_endpoint: "",
						filter_type: "promos",
						filter_group: 2,
					},
				],
				table_config: {
					metric_table: {
						table_header: [
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "View By (Hierarchy)",
							// 	key: "view_by_value",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: false,
							// 	pinned: false,
							// 	checkbox_selection: false,
							// 	row_group: false,
							// },
							{
								type: "static_column",
								mapping: "data",
								header: "View",
								key: "total",
								action: null,
								hidden: true,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Sub Class",
								key: "product_h4",
								action: null,
								hidden: true,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Class",
								key: "product_h3",
								action: null,
								hidden: true,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Department",
								key: "product_h2",
								action: null,
								hidden: true,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Division",
								key: "product_h1",
								action: null,
								hidden: true,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Actual Markdown $",
								key: "actual_markdown",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Offers",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Total",
										key: "total_offers",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "# Under Performing",
										key: "under_performing_offers",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "% Under Performing",
										key: "underperforming_percentage",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Margin",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Total",
										key: "total_margin",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "$ Increment",
										key: "incremental_margin",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "% Increment",
										key: "incremental_margin_percentage",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Positive Increment",
										key: "positive_incremental_margin",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Negative Increment",
										key: "negative_incremental_margin",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
								],
							},

							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Revenue",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Total",
										key: "total_revenue",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "$ Increment",
										key: "incremental_revenue",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "% Increment",
										key: "incremental_revenue_percentage",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Positive Increment",
										key: "positive_incremental_revenue",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Negative Increment",
										key: "negative_incremental_revenue",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
								],
							},

							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Sales Units",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Total",
										key: "total_sales_units",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "# Increment",
										key: "incremental_sales_units",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "% Increment",
										key: "incremental_sales_units_percentage",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Positive Increment",
										key: "positive_increment_sales_units",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Negative Increment",
									// 	key: "negative_increment_sales_units",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: false,
									// 	pinned: false,
									// 	row_group: false,
									// },
								],
							},
						],
						add_metrics: {},
					},
					top_bottom_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Offer Name",
								key: "offer_name",
								action: "top_bottom_offers_name",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Incremental",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Margin",
										key: "incremental_margin",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Revenue",
										key: "incremental_sales",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Sales Units",
										key: "incremental_units",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
								],
							},

							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Actual",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "Markdown $",
										key: "actual_markdown",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Margin",
										key: "actual_margin",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Revenue",
										key: "actual_sales",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Sales Units",
										key: "actual_unit",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
								],
							},
						],
						add_metrics: {},
					},
				},
			},
			MARKETING_CALENDAR: {
				filter_options: [
					{
						type: "product_h1",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					{
						type: "product_h2",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					{
						type: "product_h3",
						is_mandatory: false,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						hidden: true,
						filter_group: 1,
					},
					{
						type: "product_h4",
						is_mandatory: false,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						hidden: true,
						filter_group: 1,
					},
					{
						type: "brand",
						is_mandatory: false,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						hidden: true,
						filter_group: 1,
					},
					{
						type: "dateRange",
						is_mandatory: true,
						filter_endpoint: "",
						filter_type: "dateRange",
						use_default: true,
						filter_group: 1,
					},
					{
						type: "channelOptions",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "",
						filter_type: "channelOptions",
						filter_group: 1,
					},
					{
						type: "adTypeOptions",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "",
						filter_type: "adTypeOptions",
						filter_group: 1,
					},
					{
						type: "events",
						is_mandatory: false,
						is_multiple_selection: true,
						filter_endpoint: "get-events",
						filter_type: "events",
						hidden: true,
						filter_group: 1,
						filter_model: "METRICS_EVENTS_FILTERS_LIST",
					},
				],
				ui_elements: {
					table_setup: [
						{
							key: "total",
							display: "Total",
						},
					],
				},
				table_config: {
					events_calendar_data: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Event Name",
								key: "name",
								action: "lockUnlock",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: true,
								checkbox_selection: true,
								row_group: false,
								width: "300px",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Start Date",
								key: "start_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "End Date",
								key: "end_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Status",
								key: "event_lifecycle",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "No. Of Promos",
								key: "promosCount",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Customer Segment",
								key: "customer_segment",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Performance",
								key: "event_status",
								action: "performance",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "#Products",
								key: "productsCount",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: " #Stores",
							// 	key: "storeCount",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	row_group: false,
							// },
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Sales Unit",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "units_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline (Finalized)",
										key: "sales_units_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental (Finalized)",
										key: "units_incremental_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: " Target",
									// 	key: "units_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: " IA Projected",
									// 	key: "units_ia",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: " Incremental (IA Projected)",
									// 	key: "units_incremental_ia",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Revenue",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "revenue_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline (Finalized)",
										key: "revenue_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental (Finalized)",
										key: "revenue_incremental_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: " Target",
									// 	key: "revenue_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: " IA Projected",
									// 	key: "revenue_ia",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: " Incremental (IA Projected)",
									// 	key: "revenue_incremental_ia",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Margin",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "IA projected (Finalized)",
										key: "gross_margin_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline (Finalized)",
										key: "gross_margin_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental (Finalized)",
										key: "gross_margin_incremental_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "gross_margin_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected",
									// 	key: "gross_margin_ia",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Incremental (IA Projected)",
									// 	key: "gross_margin_incremental_ia",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
								],
							},
						],
						add_metrics: {},
					},
					marketing_calendar_promo_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Offer Name",
								key: "promo_name",
								action: "lockStatus", //"lockUnlock",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: true,
								checkbox_selection: true,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Is Hero",
								key: "is_hero_promo",
								action: null,
								hidden: true,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								filter_options: true,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Event Name",
								key: "event_name",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: " Start Date",
								key: "start_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "End Date",
								key: "end_date",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Offer Status",
								key: "lifecycle",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Performance",
								key: "performance",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "#Products",
								key: "products",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							// {
							// 	type: "static_column",
							// 	mapping: "data",
							// 	header: "#Stores",
							// 	key: "stores",
							// 	action: null,
							// 	hidden: false,
							// 	aggregate: null,
							// 	sorting: true,
							// 	filter: true,
							// 	pinned: false,
							// 	row_group: false,
							// },
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Sales Units",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "units_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline (Finalized)",
										key: "units_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental (Finalized)",
										key: "units_incremental_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "units_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Incremental (IA Projected)",
									// 	key: "units_incremental_ia",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected",
									// 	key: "units_ia",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Revenue",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "revenue_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline (Finalized)",
										key: "revenue_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental (Finalized)",
										key: "revenue_incremental_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "revenue_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Incremental (IA Projected)",
									// 	key: "revenue_incremental_ia",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected",
									// 	key: "revenue_ia",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
								],
							},
							{
								type: "static_column",
								mapping: "data",
								hidden: false,
								header: "Margin",
								columns: [
									{
										type: "static_column",
										mapping: "data",
										header: "IA Projected (Finalized)",
										key: "gross_margin_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Baseline (Finalized)",
										key: "gross_margin_baseline",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Incremental (Finalized)",
										key: "gross_margin_incremental_finalized",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Target",
									// 	key: "gross_margin_target",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "Incremental (IA Projected)",
									// 	key: "gross_margin_incremental_ia",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
									// {
									// 	type: "static_column",
									// 	mapping: "data",
									// 	header: "IA Projected",
									// 	key: "gross_margin_ia",
									// 	action: null,
									// 	hidden: false,
									// 	aggregate: null,
									// 	sorting: true,
									// 	filter: true,
									// 	pinned: false,
									// 	row_group: false,
									// },
								],
							},
						],
						add_metrics: {},
					},
					copy_promo_listing_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Event Name",
								key: "event_name",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: true,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Promos Selected",
								key: "promos_copied",
								action: "promos_copied",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
						],
					},
					copy_promo_validation_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Promo Name",
								key: "name",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: true,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Details",
								key: "message",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
						],
					},
				},
			},
			CREATE_EVENT_APPLICABILITY: {
				filter_options: [
					{
						type: "product_h1",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					{
						type: "product_h2",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "product_hierarchy",
						filter_group: 1,
					},
					// {
					// 	type: "product_h3",
					// 	is_mandatory: false,
					// 	is_multiple_selection: true,
					// 	filter_endpoint: "filters",
					// 	filter_type: "product_hierarchy",
					//  filter_group: 1
					// },
					// {
					// 	type: "product_h4",
					// 	is_mandatory: false,
					// 	is_multiple_selection: true,
					// 	filter_endpoint: "filters",
					// 	filter_type: "product_hierarchy",
					//  filter_group: 1
					// },
					// {
					// 	type: "brand",
					// 	is_mandatory: false,
					// 	is_multiple_selection: true,
					// 	filter_endpoint: "filters",
					// 	filter_type: "product_hierarchy",
					//  filter_group: 1
					// },
					{
						type: "store_h1",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "store_hierarchy",
						filter_group: 2,
						filter_group_label: "Store Selection",
					},
					{
						type: "store_h2",
						is_mandatory: true,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "store_hierarchy",
						filter_group: 2,
					},
					{
						type: "store_h3",
						is_mandatory: false,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "store_hierarchy",
						filter_group: 2,
					},
					{
						type: "store_h4",
						is_mandatory: false,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "store_hierarchy",
						filter_group: 2,
					},
					{
						type: "store_h5",
						is_mandatory: false,
						is_multiple_selection: true,
						filter_endpoint: "filters",
						filter_type: "store_hierarchy",
						filter_group: 2,
					},
				],
				table_config: {
					invalid_store_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Store Id",
								key: "id",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Status",
								key: "storeStatus",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
						],
						add_metrics: {},
					},
					valid_store_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Store Id",
								key: "store_id",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: true,
								hideHeaderCheckbox: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Store Name",
								key: "store_name",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
							},
						],
						add_metrics: {},
					},
					event_edit_validation_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Promo Name",
								key: "promo_name",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Event",
								key: "event_name",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
						],
					},
					event_edit_info_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Promo Name",
								key: "promo_name",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Action",
								key: "action",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
							},
						],
					},
					event_edit_unadv_blocked_promo_table: {
						table_header: [
							{
								type: "static_column",
								mapping: "data",
								header: "Promo Name",
								key: "promo_name",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Start Date",
								key: "promo_start",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "date",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "End Date",
								key: "promo_end",
								action: null,
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								checkbox_selection: false,
								row_group: false,
								comparator: "date",
							},
							{
								type: "static_column",
								mapping: "data",
								header: "Created By",
								key: "created_by",
								action: null, //"offer_comment",
								hidden: false,
								aggregate: null,
								sorting: true,
								filter: true,
								pinned: false,
								row_group: false,
								comparator: "text",
							},
						],
					},
				},
			},
		},
		UI_CONFIG_COMMON_IN_ALL: {
			nav_config: [
				{
					key: "decision_dashboard",
					title: "Decision Dashboard",
					link: "/decision-dashboard",
					order: 1,
				},
				{
					key: "marketing-calendar",
					title: "Calendar View",
					link: "/marketing-calendar",
					order: 2,
				},
				{
					key: "workbench",
					title: "Workbench",
					link: "/workbench",
					order: 3,
				},
				{
					key: "reporting",
					title: "Reporting",
					link: "/reporting",
					order: 4,
				},
			],
			date_viewing_style: {
				type: "date_range",
				key_names: ["start_of_week_date", "end_of_week_date"],
				available_key_options: [
					"week_num",
					"start_of_week_date",
					"end_of_week_date",
				],
				avilable_types: ["date_range", "week_num"],
				data_loading: ["create-markdown"],
			},
			element_labels: {
				brand: "Brand",
				product_h1: "Division",
				product_h2: "Department",
				product_h3: "Class",
				product_h4: "Sub Class",
				product_h5: "SKU",
				store_h0: "Country",
				store_h1: "DC",
				store_h2: "Region",
				store_h3: "State",
				store_h4: "District",
				store_h5: "City",
				store_h6: "Location",
				dateRange: "Date Range",
				store_launch_date: "Store Launch Date",
				ecomm_launch_date: "Ecomm Launch Date",
				color: "Color",
				tier: "Store Tier",
				date_range: "Date Range",
				country: "Country",
				store_type: "Store Type",
				international_store: "International Store",
				store_status: "Active Stores",
				store_tier: "Store Tier",
				store_id: "Store ID",
				store_code: "Store ID",
				store_name: "Store Name",
				region: "Region",
				state: "State",
				product_code: "Product ID",
				product_name: "Product Name",
				department: "Department",
				product_class: "Class",
				product_subclass: "Sub-Class",
				item_group: "Item Group",
				bin: "Tier",
				events: "Events",
				promos: "Promotions",
				overall: "Overall",
				[DISCOUNT_LEVELS.overall]: "Overall",
				[DISCOUNT_LEVELS.product_h1]: "Division",
				[DISCOUNT_LEVELS.product_h2]: "Department",
				[DISCOUNT_LEVELS.product_h3]: "Class",
				[DISCOUNT_LEVELS.product_h4]: "Sub Class",
				[DISCOUNT_LEVELS.product_h5]: "SKU",
				finalized_markdown: "Finalized Markdown $",
				actuals_markdown: "Actualized Markdown $",
				projected_markdown: "Projected Markdown $",
				actuals_markdown: "Actualized Markdown $",
				gross_margin_target: "Target GM",
				target_gross_margin: "Target GM",
				finalized_margin: "Finalized GM",
				finalized_gross_margin: "Finalized GM",
				finalized_baseline_margin: "Baseline GM",
				finalized_baseline_gross_margin: "Baseline GM",
				finalized_incremental_margin: "Incremental GM",
				finalized_incremental_gross_margin: "Incremental GM",
				projected_margin: "IA Projected GM",
				projected_gross_margin: "IA Projected GM",
				revenue_target: "Target Revenue",
				target_revenue: "Target Revenue",
				finalized_revenue: "Finalized Revenue",
				finalized_baseline_revenue: "Baseline Revenue",
				finalized_incremental_revenue: "Incremental Revenue",
				projected_revenue: "IA Projected Revenue",
				sales_units_target: "Target Sales Units",
				target_sales_units: "Target Sales Units",
				finalized_sales_units: "Finalized Sales Units",
				finalized_baseline_sales_units: "Baseline Sales Units",
				finalized_incremental_sales_units: "Incremental Sales Units",
				actuals_sales_units: "Actualized Sales Units",
				actuals_revenue: "Actualized Revenue",
				actuals_gross_margin: "Actualized GM",
				projected_sales_units: "IA Projected Sales Units",
				scenario_1: "Scenario 1",
				scenario_2: "Scenario 2",
				affinity_margin: "Margin Affinity",
				affinity_sales: "Sales Affinity",
				aum: "AUM $",
				aur: "AUR $",
				baseline_margin: "Baseline Margin",
				baseline_sales: "Baseline Revenue",
				baseline_sales_units: "Baseline Sales Units",
				cannibalization_margin: "Cannibalization",
				cannibalization_sales: "Cannibalization",
				incremental_margin: "Incremental Margin",
				incremental_sales: "Incremental Revenue",
				incremental_sales_units: "Incremental Sales Units",
				gross_margin: "GM $",
				margin: "GM $",
				baseline: "Baseline",
				sales: "Sales",
				incremental: "Incremental",
				pull_forward_margin: "Pull Forward",
				pull_forward_sales: "Pull Forward",
				revenue: "Revenue",
				sales_units: "Sales Units",
				gm_percent: "GM %",
				affinity: "Affinity",
				cannibalization: "Cannibalization",
				pull_forward: "Pull Forward",
				total: "Total",
				bxgy: "BxGy",
				percent_off: "% Off",
				extra_amount_off: "$ Off",
				fixed_price: "PP",
				projected: "IA Projected",
				target: "Target",
				finalized: "Finalized",
				actual: "Actual",
				lift: "Lift",
				EVENTS: "Event",
				net_incremental: "Lift",
				promo_spend: "Markdown Spend ($)",
				baseline_margin: "Baseline Margin",
				margin_positive: "Margin Positive Promotions",
				margin_negative: "Margin Negative Promotions",
				baseline_revenue: "Baseline Revenue",
				revenue_positive: "Revenue Positive Promotions",
				revenue_negative: "Revenue Negative Promotions",
				channelOptions: "Channel Type",
				adTypeOptions: "Ad Type",
				REPORT: "Report",
				EVENT_AUTO_RESIMULATION: "Event",
			},
			landing_screen: "DECISION_DASHBOARD",
			productConfigurationLevel: {
				name: "Product",
			},
			metrics_ui: ["margin", "sales", "sales_units"],
		},
	},
};
